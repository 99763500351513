export const url_base = "https://nmsclientapi.dharmahealthcare.net/";
export const url_register = url_base + "api/Account/register";
export const url_patient = url_base + "user/add/patient";
export const url_login = url_base + "api/Account/login";
export const url_getActivities = url_base + "clientApi/getactivities";
export const url_getAppointments = url_base + "clientApi/getappointments?user_lid=";
export const url_searchAvailabilities = url_base + "clientApi/searchavailabilities";
export const url_getResources = url_base + "clientApi/getresources";
export const url_addAppointment = url_base + "clientApi/addappointment";
export const url_patient_info = url_base + "user/patient/info?codiceFiscale=";
export const url_patient_verify = url_base + "user/verify/patient";
export const url_rescheduleAppointmet = url_base + "clientApi/rescheduleAppointment";
export const url_deleteAppointment = url_base + "clientApi/deleteappointment";
export const url_passwordForgot = url_base + "api/Account/forgotPasswordForm";
export const url_changePassword = url_base + "api/Account/changePassword";
export const url_resendEmail = url_base + "api/Account/resendConfirmationEmail";
export const url_token = url_base + "api/Auth/token";
export const url_checkUser = url_base + "api/Account/checkUserExists";
export const url_checkPatient = url_base + "user/check/patient";
export const url_updateEmail = url_base + "user/patient/updateemail";
//variabili default
export const insurance = "PVT";
export const location = "NMS";
